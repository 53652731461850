<template>
  <div>
    <div class="card p-2 rounded-md mt-2">
      <DataTable
        :value="listadoEmpleados"
        class="p-datatable-customers p-datatable-sm"
        dataKey="id"
        filterDisplay="menu"
        responsiveLayout="scroll"
        v-model:filters="filtros"
        @sort="sort"
        :globalFilterFields="['full_name', 'email']"
        :rowClass="rowClass"
        :paginator="true" :rows="10"
        :rowsPerPageOptions="[10,20,50]"
      >
        <template #empty>
          No existen empleados registradas
        </template>
        <Column headerStyle="width: 3rem;" class="rounded-l-lg shadow-sm border-r-2 text-xs" style="text-align: center; justify-content: center;" field="id" header="Imagen">
          <template #body="{data}">
            <div class="w-8 h-8 flex-none image-fit relative" v-if="data.persona.imagen">
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full  w-full h-full"
                :src="env + data.persona.imagen"
              />
            </div>
            <div class="w-8 h-8 flex-none image-fit relative" v-else>
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full  w-full h-full"
                src="../../../../images/user.jpg"
              />
            </div>
          </template>
        </Column>
        <Column field="persona.full_name" header="Nombre" class="my-1 py-1 shadow-sm text-xs" headerClass="text-xs">
          <template #body="{data}">
            <div class="w-full h-full">
              <p class="text-xs">
                {{ data.persona.full_name }}
              </p>
            </div>
          </template>
          <template #filter="{filterModel}">
            <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Search by name"/>
          </template>
        </Column>
        <Column field="user_name" header="Datos de cargo" headerClass="text-xs" class="my-1 py-1 shadow-sm" style="min-width:10rem; color: #4A4A4A;">
          <template #body="{data}">
            <div class="w-full h-full">
              <p class="text-xs flex items-center">
                <i class="pi pi-users mr-2 mt-1"></i>
                Cargo: {{ data.Cargo ? cargos.filter(a => a.id === parseInt(data.Cargo))[0].name : '-' }}
              </p>
              <p class="text-xs flex items-center">
                <i class="pi pi-table mr-2 mt-1"></i>
                Area: {{ data.Cargo ? cargos.filter(a => a.id === parseInt(data.Cargo))[0].name : '-' }}
              </p>
              <div class="text-xs flex items-center">
                <i class="pi pi-tag mr-2 mt-1"></i>
                Sede: <p class="mx-1 font-medium">{{ data.sede ? data.sede.name : '-' }}</p>
              </div>
            </div>
          </template>
        </Column>
        <Column field="email" header="Contacto" headerClass="text-xs" class="shadow-sm" style="min-width:10rem; color: #4A4A4A; font: 15px/18px;">
          <template #body="{data}">
          <p class="text-xs flex items-center">
            <i class="pi pi-envelope mr-2 mt-1"></i>
            {{data.persona.email}}
          </p>
          <p class="text-xs flex mt-2 items-center">
            <i class="pi pi-phone mr-2 mt-1"></i>
            {{ data.persona.telefono }}
          </p>
          <p class="text-xs flex mt-2 items-center">
            <i class="pi pi-home mr-2 mt-1"></i>
            {{ data.persona.direccion }}
          </p>
          </template>
        </Column>
        <Column header="Info"
          headerClass="text-xs"
          headerStyle="width: 5rem" style="text-align: center; justify-content: center;" class="shadow-sm"
        >
          <template #body="{data}">
            <button  @click="$router.push({name: 'pharmasan.recursos-humanos.empleados.ver', params: {id: data.id}})">
              <i class="pi pi-eye"></i>
            </button>
          </template>
        </Column>
        <Column header="Editar"
          v-if="$can('pharmasan.recursos-humanos.empleados.access')"
          headerClass="text-xs"
          headerStyle="width: 5rem"
          style="text-align: center; justify-content: center;"
          class="shadow-sm"
        >
          <template #body="{data}">
            <button  @click="$router.push({name: 'pharmasan.recursos-humanos.empleados.editar', params: {id: data.id}})">
              <i class="pi pi-pencil"></i>
            </button>
          </template>
        </Column>
      </DataTable>
      <!-- <DataTable
        :value="listarEmpleados"
        class="p-datatable-customers p-datatable-sm"
        dataKey="id"
        filterDisplay="menu"
        responsiveLayout="scroll"
        @sort="sort"
        :globalFilterFields="['full_name', 'email']"
        :rowClass="rowClass"
      >
        <template #empty>
          No existen empleados registradas
        </template>
        <Column headerStyle="width: 3rem;" class="rounded-l-lg shadow-sm border-r-2 text-xs" style="text-align: center; justify-content: center;" field="id" header="Imagen">
          <template #body="{data}">
            <div class="w-8 h-8 flex-none image-fit relative" v-if="data.persona.imagen">
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full  w-full h-full"
                :src="env + data.persona.imagen"
              />
            </div>
            <div class="w-8 h-8 flex-none image-fit relative" v-else>
              <img
                alt="Midone Tailwind HTML Admin Template"
                class="rounded-full  w-full h-full"
                src="../../../../images/user.jpg"
              />
            </div>
          </template>
        </Column>
        <Column field="full_name" header="Nombre" class="my-1 py-1 shadow-sm text-xs" headerClass="text-xs">
          <template #body="{data}">
            <div class="w-full h-full">
              <p class="text-xs">
                {{ data.persona.full_name }}
              </p>
            </div>
          </template>
        </Column>
        <Column field="user_name" header="Datos de cargo" headerClass="text-xs" class="my-1 py-1 shadow-sm" style="min-width:10rem; color: #4A4A4A;">
          <template #body="{data}">
            <div class="w-full h-full">
              <p class="text-xs flex items-center">
                <i class="pi pi-users mr-2 mt-1"></i>
                Cargo: {{ data.Cargo ? cargos.filter(a => a.id === parseInt(data.Cargo))[0].name : '-' }}
              </p>
              <p class="text-xs flex items-center">
                <i class="pi pi-table mr-2 mt-1"></i>
                Area: {{ data.Cargo ? cargos.filter(a => a.id === parseInt(data.Cargo))[0].name : '-' }}
              </p>
              <div class="text-xs flex items-center">
                <i class="pi pi-tag mr-2 mt-1"></i>
                Sede: <p class="mx-1 font-medium">{{ data.sede_nombre }}</p>
              </div>
            </div>
          </template>
        </Column>
        <Column field="email" header="Contacto" headerClass="text-xs" class="shadow-sm" style="min-width:10rem; color: #4A4A4A; font: 15px/18px;">
          <template #body="{data}">
          <p class="text-xs flex items-center">
            <i class="pi pi-envelope mr-2 mt-1"></i>
            {{data.persona.email}}
          </p>
          <p class="text-xs flex mt-2 items-center">
            <i class="pi pi-phone mr-2 mt-1"></i>
            {{ data.persona.telefono }}
          </p>
          <p class="text-xs flex mt-2 items-center">
            <i class="pi pi-home mr-2 mt-1"></i>
            {{ data.persona.direccion }}
          </p>
          </template>
        </Column>
        <Column header="Info"
          headerClass="text-xs"
          headerStyle="width: 5rem" style="text-align: center; justify-content: center;" class="shadow-sm"
        >
          <template #body="{data}">
            <button  @click="$router.push({name: 'pharmasan.recursos-humanos.empleados.ver', params: {id: data.id}})">
              <i class="pi pi-eye"></i>
            </button>
          </template>
        </Column>
        <Column header="Editar"
          v-if="$can('pharmasan.recursos-humanos.empleados.access')"
          headerClass="text-xs"
          headerStyle="width: 5rem"
          style="text-align: center; justify-content: center;"
          class="shadow-sm"
        >
          <template #body="{data}">
            <button  @click="$router.push({name: 'pharmasan.recursos-humanos.empleados.editar', params: {id: data.id}})">
              <i class="pi pi-pencil"></i>
            </button>
          </template>
        </Column>
      </DataTable> -->
      <!-- <Paginator v-model:first="offset"
        :rows="limit"
        :totalRecords="totalItemsCount"
        :rowsPerPageOptions="[2,10,20,30]"
        @page="onPage($event)"
      /> -->
    </div>
  </div>
</template>
<script>
import { ref, onMounted, computed, watch } from 'vue'
// import { FilterMatchMode } from 'primevue/api'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import EmpleadosService from '../../../../services/empleados.service'
import EmpleadosServiceNew from '../../../../services_back_new/empleados.service'
import empleadosStore from '../../../../stores/empleados.store'
import CargosService from '../../../../services/cargos.service'
import SedesService from '../../../../services/sedes.service'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
dayjs.extend(duration)
export default {
  name: 'TablaEmpledos',
  setup () {
    onMounted(() => {
      obtenerSedes()
      getCargos()
      obtenerEmpleados()
    })
    // Services
    const _SedesService = ref(new SedesService())
    const _CargosService = ref(new CargosService())
    const _EmpleadosServiceNew = ref(new EmpleadosServiceNew())
    // computed
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value
      }
    })
    const filtroNombre = computed(() => empleadosStore.getters._filtro_tabla_empleados_nombre)
    /** data **/
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const cargos = ref([])
    const sedes = ref([])
    const listadoEmpleados = ref([])
    const listarEmpleados = ref([])
    const totalItemsCount = ref(0)
     const _EmpleadosService = ref(new EmpleadosService())
     const env = process.env.VUE_APP_MICROSERVICE_API_USUARIOS.split('/api')[0]
     const filtros = ref({
        'persona.full_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
    })
    /** methods **/
    const initFilters = () => {
      filtros.value = {
        'persona.full_name': { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }] }
      }
    }
    const obtenerSedes = () => {
      _SedesService.value.paginate().then(({ data }) => {
        sedes.value = data
        getEmpleados(params.value)
      })
    }
    const obtenerEmpleados = () => {
      _EmpleadosServiceNew.value.get().then(({ data }) => {
        listadoEmpleados.value = data
      })
    }
    const getEmpleados = (parameters) => {
      _EmpleadosService.value.paginate(parameters).then(({ data }) => {
        for (const i of data.rows) {
          listarEmpleados.value.push({
            ...i,
            sede_nombre: sedes.value.find(a => a.id === i.contratos.estacion_trabajo.branch_office_id).name
          })
        }
        // listarEmpleados.value = data.rows
        totalItemsCount.value = data.count
      })
    }
    const getCargos = () => {
      _CargosService.value.paginate().then(({ data }) => {
        cargos.value = data
      })
    }
    const rowClass = (data) => {
      return data.id ? 'rowStyle' : null
    }

    const sort = (evt) => {
      console.log({ evt })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      getEmpleados(params.value)
    }
    /** watch **/
    watch(filtroNombre, (nuevo, viejo) => {
      if (nuevo === '') {
        params.value.search = ''
        getEmpleados(params.value)
      } else {
        params.value.search = nuevo
        getEmpleados(params.value)
      }
    })
    /** return **/
    return {
      totalItemsCount,
      offset,
      limit,
      sort,
      onPage,
      params,
      listarEmpleados,
      rowClass,
      filtroNombre,
      env,
      cargos,
      listadoEmpleados,
      filtros,
      initFilters
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep(.pi) {
    font-size: 0.8rem;
  }
  ::v-deep(.rowStyle) {
    // box-shadow: 3px 3px 10px 10px #0000001A;
    border-radius: 5px;
    margin: 5px !important;
    background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 5px 4px 18px #0000001A;
    opacity: 1;
  }
</style>
