import http from '@/libs/http'
const baseUrl = process.env.VUE_APP_MICROSERVICE_API_EMPLEADOS

export default class EmpleadosService {
    post (params) {
        return http.post(`${baseUrl}/empleados/crear-empleado`, params, {
            headers: {
                loading: true
            }
        })
    }

    get () {
        return http.get(`${baseUrl}/empleados/listar-empleados`, {
            headers: {
                loading: true
            }
        })
    }
}
